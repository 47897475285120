import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Jumbotron } from "react-bootstrap";

const HomePage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={siteTitle} />
      <h1 className="display-1">Hi, I'm Brian.</h1>
      <p>I'm a software developer currently working at <a href="https://www.duolingo.com/careers">Duolingo</a>, and previously I worked at Facebook and Microsoft.</p>
      <p>I'm currently studying how to use technology to improve education.</p>
    </Layout>
  )
}

export default HomePage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`